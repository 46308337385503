import React, {useEffect, useRef} from 'react'
import styled from '@emotion/styled'
import {Container} from "../Styles/containers";
import me from '../../images/midas.jpg'
import {colors, letterSpacing, pageTransitionTimeMs, spacing, transitions} from "../Styles/variables";
import glitch from "../Styles/glitch";
import Button, {buttonStyle} from "../Button";
import {ScrollScene} from "scrollscene";
import isOnMobile from "../../isOnMobile";
import {animateScroll, Link} from "react-scroll";
import {useNavigate} from "react-router-dom";
import ReadMoreButton from "../ReadMoreButton";

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: 100dvh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  
  .about-me__animate {
    opacity: 0;
    transition: ${transitions.page};
    transition-delay: .4s;
    
    &:first-child {
      transition-delay: .3s;
    }
    
    &:last-child {
      transition-delay: .5s
    }
  }
  
  &.in-view {
    .image--overlay {
      transform: translateY(-100%);
    }
  
    .about-me__animate {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const ImageOverlay = styled.div`
  position: absolute;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0, 0, 0, 0) 100%);
  height: 200vh;
  width: 100vw;
  top: 0;
  left: 0;
  transform: translateY(0);
  transition: 2s;
  transition-delay: .5s;
`

const ContentWrapper = styled.div`
  padding: ${spacing.md};
  width: 100%;
  text-align: center;
  
  @media only screen and (min-width: 992px) {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-35%, -50%);
    max-width: 500px;
    padding:0;
    text-align: left;
  }
`

const MyImage = styled.img`
  margin: 0 auto;
  display: block;
  transform: translateY(50%);
  width: 50%;
  opacity: .7;
  z-index: -100;
  
  @media only screen and (min-width: 992px) {
    opacity: .7;
    height: 100vh;
    left: 45%;
    position: absolute;
    bottom: 0;
    width: auto;
  }
`

const Glitch = styled.h1`
  position: relative;
  color: white;
  font-size: 2.5em;
  letter-spacing: ${letterSpacing.action};
  max-width: 100%;
  z-index: 1;
  
  @media only screen and (min-width: 992px) {
    font-size: 4em;
  }
  
  ${glitch}
`

const AboutText = styled.p`
  margin-bottom: ${spacing.lg};
`

const Highlight = styled.span`
  text-shadow: 1px 1px 0 ${colors.glitchPink}, -1px -1px 0 ${colors.glitchBlue};
  font-weight: bold;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${spacing.md};

  @media only screen and (max-width: 992px) {
      flex-direction: column;
  }
`

const StyledScrollButton = styled(Link)`
    ${buttonStyle};
    width: 100%;
    margin-right: ${spacing.md};
    background-color: purple;

    @media only screen and (min-width: 992px) {
        width: auto;
        margin-bottom: 0;
    }
`

const AboutMe = () => {
    const navigate = useNavigate()
    const wrapper = useRef(null)
    const image = useRef(null)
    const content = useRef(null)
    let currentMouse = {x:0, y: 0}
    let previousMouse = {x:0, y:0}
    const currentDifference = {x: 0, y:0}

    const mouseUpdate = ({screenY, screenX}) => {
        currentMouse = {y: screenY, x: screenX}
    }

    useEffect(() => {
        new ScrollScene({
            triggerElement: wrapper.current,
            toggle: {
                element: wrapper.current,
                className: "in-view"
            },
            offset: wrapper.current.offsetHeight / 2,
        })

        wrapper.current.addEventListener("mousemove", mouseUpdate, false);
    }, [wrapper.current])

    const slowDownImageMultiplier = 100
    const slowDownContentMultiplier = -30

    const animateImage = () => {
        requestAnimationFrame( animateImage );
        if(!content?.current?.style || !image?.current?.style) return;

        const difference = {
            x: previousMouse.x - currentMouse.x,
            y: previousMouse.y - currentMouse.y
        }

        currentDifference.x += difference.x
        currentDifference.y += difference.y

        image.current.style.transform = `translate(calc(-10% + ${currentDifference.x / slowDownImageMultiplier}px), ${currentDifference.y  / slowDownImageMultiplier}px)`
        content.current.style.transform = `translate(calc(-35% - ${currentDifference.x / slowDownContentMultiplier}px), calc(-50% - ${currentDifference.y  / slowDownContentMultiplier}px))`

        previousMouse = currentMouse
    }

    useEffect(() => {
        if(!isOnMobile()) {
            animateImage()
        }
    }, [image.current, content.current]);

    const switchPage = (newUrl = '/') => {
        animateScroll.scrollToTop({
            duration: pageTransitionTimeMs,
            smooth: 'easeInOutQuart'
        })

        navigate(newUrl)
    }

    return (
        <PageWrapper ref={wrapper} id="about-me">
            <Container>
                <ImageOverlay className="image--overlay"/>
                <MyImage ref={image} src={me} alt="Midas Minnegal freelance web development." />
                <ContentWrapper ref={content}>
                    <div className="about-me__animate" >
                        <Glitch data-text="ABOUT ME">ABOUT ME</Glitch>
                    </div>
                    <AboutText className="about-me__animate">
                        My name is <Highlight>Midas Minnegal</Highlight>. I am a <Highlight>front-end developer</Highlight> with back-end development experience and a can-do attitude. I like spending my spare time building innovative and minimalist interfaces. My goal is to create interfaces which not only look good, but <Highlight>which people enjoy</Highlight> using.
                    </AboutText>
                    <ButtonWrapper className="about-me__animate">
                        <StyledScrollButton to="contact-me" smooth data-text="Contact me">Contact me</StyledScrollButton>
                        <Button onClick={() => switchPage('/projects')} text="Check out my work"/>
                    </ButtonWrapper>
                </ContentWrapper>
            </Container>
        </PageWrapper>
    )
}

export default AboutMe
