import React, {useEffect, useRef} from 'react'
import styled from "@emotion/styled"
import {colors, letterSpacing, spacing, transitions} from "../Styles/variables"
import { ScrollScene } from 'scrollscene'
import ScrollIcon from "../ScrollIcon"
import {keyframes} from "@emotion/react"
import { Link } from "react-scroll"
import glitch from "../Styles/glitch";
import ReadMoreButton from "../ReadMoreButton";

const LandingWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  min-height: 100dvh;
  overflow: hidden;
  z-index: 2;

  .animated-text {
    opacity: 0;
    transition-delay: .3s;
    transition: ${transitions.pageSlow};
  }

  &.in-view {
    .animated-text {
      opacity: 1;
    }
  }
`

export const Glitch = styled.h1`
  position: relative;
  color: ${colors.white};
  font-size: 2.5em;
  letter-spacing: ${letterSpacing.action};
  text-align: center;
  max-width: 100%;
  z-index: 1;
  padding: 0 ${spacing.md};

  @media only screen and (min-width: 992px) {
    font-size: 4em;
  }

  ${glitch};
`

const StyledGlitch = styled.h2`
    position: relative;
    color: ${colors.white};
    font-size: 1.5em;
    letter-spacing: ${letterSpacing.action};
    text-align: center;
    max-width: 100%;
    z-index: 1;
    padding: 0 ${spacing.md};
    
  @media only screen and (min-width: 992px) {
    font-size: 2.125rem;
  }

  ${glitch}
`

const Landing = () => {
  const wrapper = useRef(null)

  useEffect(() => {
    new ScrollScene({
      triggerElement: wrapper.current,
      toggle: {
        element: wrapper.current,
        className: "in-view"
      }
    })
  })

  return(
    <>
      <LandingWrapper ref={wrapper}>
        <div>
          <Glitch className="animated-text" data-text="MIDAS MINNEGAL">MIDAS MINNEGAL</Glitch>
          <StyledGlitch className="animated-text" data-text="DEVELOPMENT AND DESIGN">DEVELOPMENT AND DESIGN</StyledGlitch>
        </div>
        <ReadMoreButton targetId={"my-work"} />
      </LandingWrapper>
    </>
  )
}

export default Landing
