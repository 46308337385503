import React, {useEffect, useRef} from 'react'
import {Container} from "../Styles/containers";
import PhoneCar from "../PhoneCar";
import {colors, pageTransitionTimeMs, spacing, transitions} from "../Styles/variables";
import styled from "@emotion/styled";
import {ScrollScene} from "scrollscene";
import Button from "../Button";
import {animateScroll} from "react-scroll";
import {useNavigate} from "react-router-dom";
import ReadMoreButton from "../ReadMoreButton";

const Wrapper = styled.div`
    padding: calc(${spacing.xl} * 2) 0;
    display: flex;
    align-items: center;
    gap: ${spacing.md};
    justify-content: space-between;
    min-height: 90vh;
    position: relative;

    @media only screen and (max-width: 992px) {
        flex-direction: column-reverse;
        padding: 0;
        min-height: 100dvh;
    }
`

const HideOnMob = styled.div`
    @media only screen and (max-width: 992px) {
        display: none;
    }
`

const TextContent = styled.div`
    padding: ${spacing.md};
    width: 40%;
    
    &.in-view {
        .text-item,
        .button-wrapper {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media only screen and (max-width: 992px) {
        width: 100%;
        margin-bottom: -10%;
        position: relative;
        z-index: 1;
        text-shadow: 4px 4px 0 ${colors.black};
    }
`

const TextBig = styled.div`
    position: relative;
    text-align: left;
    font-size: 5rem;
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;
    opacity: 0;
    transform: translateY(-100%);
    transition: .3s;
    transition-delay: ${({delay}) => delay};
    font-style: italic;

    @media only screen and (max-width: 992px) {
        font-size: 3.5rem;
        text-align: center;
    }
`

const ButtonWrapper = styled.div`
    margin-top: ${spacing.md};
    opacity: 0;
    transition: .3s;
    transition-delay: 1.4s;
    transform: translateY(-${spacing.lg});

    @media only screen and (max-width: 992px) {
        display: none;
    }
`

const PhoneButtonWrapper = styled.div`
    position: relative;
    z-index: 1;
    background-color: ${colors.trueBlack};
    display: none;
    margin-top: -8%;

    @media only screen and (max-width: 992px) {
        display: block;
    }
`

const TextBigWrapper = styled.div`
    overflow: hidden;
`

const MyWork = () => {
  const textWrapper = useRef(null)
  const navigate= useNavigate()

  useEffect(() => {
    new ScrollScene({
      triggerElement: textWrapper.current,

      toggle: {
        element: textWrapper.current,
        className: "in-view"
      },
    })
  }, [])

  const switchPage = (newUrl = '/') => {
    animateScroll.scrollToTop({
      duration: pageTransitionTimeMs,
      smooth: 'easeInOutQuart'
    })

    navigate(newUrl)
  }

  return (
    <Container id="my-work">
      <Wrapper>
        <PhoneButtonWrapper className="button-wrapper">
          <Button onClick={() => switchPage('/projects')} text="Check out my work"/>
        </PhoneButtonWrapper>
        <PhoneCar />
        <TextContent ref={textWrapper}>
          <TextBigWrapper>
            <TextBig className="text-item" delay=".5s">I CREATE</TextBig>
          </TextBigWrapper>
          <TextBigWrapper>
            <TextBig className="text-item" delay=".8s">INTERFACES</TextBig>
          </TextBigWrapper>
          <TextBigWrapper>
            <TextBig className="text-item" delay="1.1s">THAT POP!</TextBig>
          </TextBigWrapper>
          <ButtonWrapper className="button-wrapper">
            <Button onClick={() => switchPage('/projects')} text="Check out my work"/>
          </ButtonWrapper>
        </TextContent>
        <HideOnMob>
          <ReadMoreButton targetId="about-me" />
        </HideOnMob>
      </Wrapper>
    </Container>
  )
}

export default MyWork