import React, {useEffect, useRef} from 'react'
import carTransparent from './car-transparent.png'
import carBg from './car-bg.jpg'
import phoneTransparent from './phone-transparent.png'
import phoneMask from './phone-mask.png'
import phoneMaskExtended from './phone-mask-extended.png'
import styled from "@emotion/styled";
import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'
import {keyframes} from "@emotion/react";

gsap.registerPlugin(ScrollTrigger);

const width = '50%'
const widthMobile = '100%'

const Wrapper = styled.div`
    height: 0;
    width: ${width};
    padding-bottom: ${width};
    position: relative;
    mix-blend-mode: exclusion;
    transform: translateX(35%) ;

    @media only screen and (max-width: 992px) {
        width: ${widthMobile};
        padding-bottom: ${widthMobile};
      }
`

const StyledImage = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const StyledCarBgImage = styled(StyledImage)`
    transform: translateX(-35%);
`

const glowAnimation = keyframes`
    0% {
        filter: drop-shadow(0 -6px 2px rgba(255,0,193,.5)) drop-shadow(0px 6px 2px rgba(0,255,249,.15));
    }
    50% {
        filter: drop-shadow(0 -6px 3px rgba(255,0,193,.25)) drop-shadow(0px 6px 3px rgba(0,255,249,.25));
    }
    100% {
        filter: drop-shadow(0 -6px 2px rgba(255,0,193,.5)) drop-shadow(0px 6px 2px rgba(0,255,249,.15));
    }
`

const StyledCarImage = styled(StyledCarBgImage)`
    animation: ${glowAnimation} 10s infinite;
`

const PhoneMask = styled.div`
    mask-image: url(${phoneMask});
    mask-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`

const PhoneMaskExtended = styled(PhoneMask)`
    mask-image: url(${phoneMaskExtended});
`

const PhoneCar = () => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const wrapperAnimation = gsap.to(wrapperRef.current, {
      x: '0',
    })
    const carAnimation = gsap.to(wrapperRef.current.querySelectorAll('.car-img'), {
      x: '0',
    })

    ScrollTrigger.create({
      trigger: wrapperRef.current,
      animation: wrapperAnimation,
      start: "top bottom",
      scrub: 1,
      end: "bottom top"
    })

    ScrollTrigger.create({
      trigger: wrapperRef.current,
      animation: carAnimation,
      start: "top bottom",
      scrub: 1,
      end: "bottom top"
    })
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <PhoneMask>
        <StyledCarBgImage className="car-img" src={carBg} alt="race car with background" />
      </PhoneMask>
      <StyledImage src={phoneTransparent} alt="race car with background" />
      <PhoneMaskExtended>
        <StyledCarImage className="car-img" src={carTransparent} alt="race car transparent" />
      </PhoneMaskExtended>
    </Wrapper>
  )
}

export default PhoneCar
