import {colors} from "./variables";
import {css} from "@emotion/react";

const glitchCopy = `
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const getGlitchSkewAnimation = () => {
  let result = ''
  const steps = 20
  const stepPercentMultiplier = 1 / steps
  for (let i = 0; i < steps; i++) {
    result += (i * stepPercentMultiplier) * 100 + '% {'
    result += `transform: skew(${Math.random() * 7}deg);`
    result += '}'
  }

  return result
}

const getGlitchAnimation = () => {
  let result = ''
  const steps = 20
  const stepPercentMultiplier = 1 / steps
  for (let i = 0; i < steps; i++) {
    result += (i * stepPercentMultiplier) * 100 + '% {'
    result += `clip: rect(${Math.random() * (100)}px, 9999px, ${Math.random() * (100)}px, 0);`
    result += `transform: skew(${Math.random() * (100)} / 100) + deg);`
    result += '}'
  }

  return result
}

const glitch = css`
  overflow: hidden;
  animation: glitch-skew 5s infinite linear alternate-reverse;
  &::before{
    ${glitchCopy};
    left: 2px;
    text-shadow: -2px 0 ${colors.glitchPink};
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim 5s infinite linear alternate-reverse;
  }

  &::after {
    ${glitchCopy};
    left: -2px;
    text-shadow: -2px 0 ${colors.glitchBlue}, 2px 2px ${colors.glitchPink};
    animation: glitch-anim2 1s infinite linear alternate-reverse;
  }
}

@keyframes glitch-anim {
  ${getGlitchAnimation()}
}

@keyframes glitch-anim2 {
  ${getGlitchAnimation()}
}

@keyframes glitch-skew {
  ${getGlitchSkewAnimation()}
}
`

export default glitch
