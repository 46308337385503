import React from 'react'
import ScrollIcon from "../ScrollIcon";
import styled from "@emotion/styled";
import {Link} from "react-scroll";
import {letterSpacing, spacing, transitions} from "../Styles/variables";
import glitch from "../Styles/glitch";
import {keyframes} from "@emotion/react";

const IconAnimationActive = keyframes`
  0%{
    transform: translateY(5px);
  }
  50%{
    transform: translateY(30px);
  }
  100%{
    transform: translateY(5px);
  }
`

const ScrollDown = styled(Link)`
  cursor: pointer;
  color: white;
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 14px;
  letter-spacing: ${letterSpacing.action};
  font-weight: bold;
  opacity: .5;
  transition: ${transitions.base};
  z-index: 1;
  margin-bottom: calc(${spacing.lg});
  position: absolute;
  bottom: ${spacing.md};
  left: 50%;
  transform: translateX(-50%);
  outline: 0;

  @media only screen and (max-width: 992px) {
    margin-bottom: ${spacing.lg};
  }

  &:hover,
  &:focus {
    opacity: 1;

    svg g {
      animation: ${IconAnimationActive} 1s infinite;
    }

    .scroll-text {
      ${glitch};
    }
  }
`

const ScrollText = styled.span`
  margin-top: 15px;
`


const ReadMoreButton = ({targetId}) => {
  return (
    <ScrollDown className="mouse-hover" href={`#${targetId}`} to={targetId} smooth>
      <ScrollIcon />
      <ScrollText className="scroll-text" data-text="MORE BELOW">
        MORE BELOW
      </ScrollText>
    </ScrollDown>
  )
}

export default ReadMoreButton